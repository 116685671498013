<script>
import { mapState } from 'vuex';

export default {
  name: 'nft',
  computed: {
    ...mapState({
      chainId: (state) => state.User.chainId,
    }),
  },
  methods: {
    async dispatchPools() {
      await this.$store.dispatch('Pools/changePools');
      await this.$store.dispatch('Pools/update', 'nft');
    },
  },
  watch: {
    chainId: {
      handler() {
        this.dispatchPools();
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style>

</style>
